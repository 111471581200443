import { PoolConfig, QuoteToken, PoolCategory } from './types'

const pools: PoolConfig[] = [
  {
    sousId: 0,
    tokenName: 'BTL',
    tokenAddress: '0x8Ad862D4083A57d10C14efc49382518812850Ce0',   // token address
    stakingTokenName: QuoteToken.CAKE,
    stakingTokenAddress: '0x8Ad862D4083A57d10C14efc49382518812850Ce0',  // token address
    contractAddress: {
      97: '',
      56: '0x9d548327588c2040d817a6a0568C62D8633DC8f0',  /// masterchef address
    },
    poolCategory: PoolCategory.CORE,
    projectLink: '/',
    harvest: true,
    tokenPerBlock: '10',
    sortOrder: 1,
    isFinished: false,
    tokenDecimals: 18,
  },
 
]

export default pools
