import contracts from './contracts'
import { FarmConfig, QuoteToken } from './types'

const farms: FarmConfig[] = [
  {
    pid: 0,
    lpSymbol: 'BTL',
    lpAddresses: {
      97: '',
      56: '0x8Ad862D4083A57d10C14efc49382518812850Ce0',
    },
    tokenSymbol: 'SYRUP',
    tokenAddresses: {
      97: '',
      56: '0x8Ad862D4083A57d10C14efc49382518812850Ce0',
    },
    quoteTokenSymbol: QuoteToken.BNB,
    quoteTokenAdresses: contracts.wbnb,
  },

  {
    pid: 1,
    lpSymbol: 'BTL-BNB LP',
    lpAddresses: {
      97: '',
      56: '0xaD38A480CaBCbB9CE62006f8B0A0A27cEb0fAc2b',   // lp address token-bnb
    },
    tokenSymbol: 'BTL',
    tokenAddresses: {
      97: '',
      56: '0x8Ad862D4083A57d10C14efc49382518812850Ce0', // token address
    },
    quoteTokenSymbol: QuoteToken.BNB,
    quoteTokenAdresses: contracts.wbnb,
  },

]

export default farms
